@import './grid.scss';
@import './icons.css';
// Fonts
@font-face {
  font-family: 'nunito-extrabold';
  src: url('../font/NunitoSans-ExtraBold.ttf');
}

@font-face {
  font-family: 'nunito-bold';
  src: url('../font/NunitoSans-Bold.ttf');
}

@font-face {
  font-family: 'nunito-semibold';
  src: url('../font/NunitoSans-SemiBold.ttf');
}

@font-face {
  font-family: 'nunito-regular';
  src: url('../font/NunitoSans-Regular.ttf');
}

@font-face {
  font-family: 'nunito-italic';
  src: url('../font/NunitoSans-Italic.ttf');
}

@font-face {
  font-family: 'nunito-light';
  src: url('../font/NunitoSans-Light.ttf');
}

@keyframes fade-right {
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

button:disabled,
button[disabled] {
  cursor: not-allowed;
}

// Mixin
@mixin width($width) {
  max-width: $width !important;
  width: 100% !important;
}

@mixin placeholder-color($color) {
  &::-webkit-input-placeholder {
    /* WebKit browsers */
    color: $color;
  }
  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: $color;
  }
  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: $color;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: $color;
  }
}

@mixin translate($translate) {
  -moz-transform: translateX($translate);
  -webkit-transform: translateX($translate);
  -ms-transform: translateX($translate);
  -o-transform: translateX($translate);
  transform: translateX($translate);
}

@mixin transition($transition) {
  -moz-transition: all $transition ease;
  -o-transition: all $transition ease;
  -webkit-transition: all $transition ease;
  -ms-transition: all $transition ease;
  transition: all $transition ease;
}

@mixin transform($transform) {
  -moz-transform: $transform;
  -o-transform: $transform;
  -webkit-transform: $transform;
  -ms-transform: $transform;
  transform: $transform;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

%clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

button {
  cursor: pointer;
}

.middle-align {
  display: inline-block;
  vertical-align: middle;
}

.vertical-align {
  position: absolute;
  top: 50%;
  left: 50%;
  @include transform(translate(-50%, -50%));
}

// Normalizer
body {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  margin: 0;
  &.grey-bg {
    background-color: #f6f7fb;
  }
}

ul,
ol {
  margin: 0;
  padding: 0;
}

input,
button,
a,
textarea {
  &:focus {
    outline: none;
  }
}

a {
  text-decoration: none;
}

// Buttons
.btn-transparent,
.btn-blue {
  font: 14px 'nunito-bold';
  height: 42px;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
  padding: 0 15px;
  max-width: 100%;
  cursor: pointer;
  @include transition(0.2s);
  @include border-radius(6px);
}

.btn-transparent {
  color: #003366;
  border: 1px solid #00a5fe;
  background-color: #fff;
  &:hover {
    background-color: #00a5fe;
    color: #fff;
  }
}

.btn-blue {
  color: #fff;
  border: 1px solid #00a5fe;
  background-color: #00a5fe;
  box-shadow: 0 10px 23.75px 1.25px rgba(52, 59, 75, 0.12);
  &:hover {
    box-shadow: 0 20px 32.8px 7.2px rgba(52, 59, 75, 0.22);
  }
}

.btn-white {
  font: 14px 'nunito-bold';
  color: #003366;
  background-color: #ffffff;
  text-transform: uppercase;
  position: relative;
  padding: 0 55px;
  line-height: 48px;
  box-shadow: 0 10px 23.75px 1.25px rgba(52, 59, 75, 0.12);
  display: inline-block;
  @include border-radius(6px);
  @include transition(0.2s);
  svg,
  span {
    position: absolute;
    right: 15px;
    top: 50%;
    @include transform(translateY(-50%));
    @include transition(0.1s);
  }
  svg {
    fill: #009bef;
  }
  span {
    color: #009bef;
  }
  &:hover {
    box-shadow: 0 20px 32.8px 7.2px rgba(52, 59, 75, 0.22);
    svg,
    span {
      right: 10px;
    }
  }
}

.btn-double {
  white-space: nowrap;
  color: #fff;
  font-size: 0;
  max-width: 100%;
  box-shadow: 0 20px 23.75px 1.25px rgba(52, 59, 75, 1);

  &:hover {
    box-shadow: 0 17px 42.5px 7.5px rgba(0, 155, 239, 0.29);
    .link-icon {
      background-color: #1aa5f1;
    }
    .link-content {
      background-color: #009bef;
      svg {
        right: 10px;
      }
    }
  }
  svg {
    fill: #fff;
  }
  .link-icon {
    background-color: #1aaefe;
    width: 48px;
    height: 48px;
    position: relative;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    @include transition(0.2s);
    @extend .middle-align;
    svg {
      @extend .vertical-align;
    }
  }
  .link-content {
    background-color: #00a5fe;
    font: 14px 'nunito-bold';
    line-height: 48px;
    width: 290px;
    max-width: calc(100% - 52px);
    padding: 0 30px 0 10px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    @extend .middle-align;
    @include transition(0.2s);
    &.center-text {
      text-align: center;
      text-transform: uppercase;
    }
    svg {
      position: absolute;
      right: 15px;
      top: 50%;
      @include transform(translateY(-50%));
      @include transition(0.1s);
    }
  }
}

// Header

header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  padding: 25px 0;
  z-index: 3;
  .home-link {
    float: left;
    width: 183px;
    height: 52px;
    background: url('../img/logo.png') no-repeat center;
    background-size: contain;
  }
  @extend %clearfix;
  &.white-menu {
    .home-link {
      background: url('../img/logo-white.png') no-repeat center;
      background-size: contain;
    }
    nav li a {
      color: #fff;
      &:hover {
        color: #fff;
      }
    }
  }
}

header.active {
  background-color: #fff;
  font-size: 15px;
  padding: 0;
  box-shadow: 0px 9px 15px 0px rgba(69, 83, 94, 0.08);
  transition: 0.3s ease;

  .home-link {
    width: 125px;
    height: 50px;
  }

  nav li a {
    padding: 15px 0;
  }

  .navbar-toggler {
    margin: 12px 5px;
  }
}

header.white-menu.active {
  .home-link {
    background: url('/images/logo.png?66c4107702f248785e6bad92a020dbc3') no-repeat center;
    background-size: contain;
  }

  nav a {
    color: #003366 !important;
  }

  .navbar-toggler {
    fill: #003366;
  }
}

nav {
  float: right;
  li {
    @extend .middle-align;
    &:not(:first-child) {
      margin-left: 40px;
    }
    a {
      font: 17px 'nunito-bold';
      color: #003366;
      padding: 15px 0;
      position: relative;
      display: block;
      @include transition(0.2s);
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 3px;
        background-color: #00a5fe;
        @include border-radius(3px);
        @include transform(translateX(-50%));
        @include transition(0.2s);
      }
      &:hover {
        color: #00a5fe;
      }
    }
    &.active {
      a {
        &:hover {
          color: #003366;
        }
        &:after {
          width: 100%;
        }
      }
    }
  }
}

.navbar-toggler {
  display: none;

  background: transparent;
  border: 0;
  margin: 15px;
  fill: #2996fe;
}

.white-menu .navbar-toggler {
  fill: #ffffff;
}

// Footer
footer {
  padding-top: 65px;
  border-top: 1px solid #eaf0f6;
  .footer-flex {
    display: flex;
    padding-bottom: 55px;
    position: relative;
    z-index: 1;
  }
  h4 {
    text-transform: uppercase;
    font: 14px 'nunito-bold';
    color: #003366;
    margin: 0 0 20px;
  }
  p {
    font: 16px 'nunito-regular';
    color: #45535e;
    margin: 0 0 10px;
    line-height: 1.5;
    span {
      display: block;
    }
  }
  .socials {
    margin-top: 30px;
    height: 28px;
    overflow: hidden;

    a {
      svg {
        fill: #8b98ab;
      }
      &:not(:last-child) {
        margin-right: 10px;
      }
      &:hover {
        svg {
          fill: #009bef;
        }
      }
    }
  }
}

.footer-logo {
  width: 29%;
}

.footer-nav {
  width: 23%;
  a {
    font: 16px 'nunito-semibold';
    color: #45535e;
    line-height: 1;
    @include transition(0.2s);
    &:hover {
      color: #009bef;
    }
  }
}

.footer-contact {
  width: calc(48% - 190px);
  a {
    color: #009bef;
  }
}

.footer-call {
  width: 190px;
  .btn-transparent,
  .btn-blue {
    min-width: 190px;
    margin-bottom: 15px;
  }
}

.copyright {
  border-top: 1px solid #eaf0f6;
  color: #003366;
  text-align: center;
  font: 13px 'nunito-regular';
  padding: 45px 0;
  a {
    color: #009bef;
  }
}

// Home page
.home-head {
  overflow: hidden;
  background-color: #f6f7fb;
  padding: 165px 0 90px;
  @extend %clearfix;
  .home-head-left {
    width: 41%;
    float: left;
    h1 {
      font: 44px 'nunito-regular';
      line-height: 1.2;
      color: rgb(25, 25, 25);
      margin: 0 0 30px;
    }
    p {
      font: 20px 'nunito-regular';
      color: #45535e;
      margin: 0;
    }
  }
  .home-head-right {
    width: 47%;
    float: right;
    position: relative;
    margin-bottom: 60px;
    .home-shadow {
      position: absolute;
      opacity: 0.4;
      left: 18%;
      top: 20%;
      width: 100%;
      height: 100%;
    }
  }
  .error-message {
    font: 11px 'nunito-regular';
    color: #ff471e;
    // display: none;
    margin-top: 5px;
    display: block;
  }
}

.form-error {
  font: 12px 'nunito-bold';
  max-width: 610px;
  margin: 0 auto;
  color: #ff471e;
}

.home-profile {
  background-color: #fff;
  box-shadow: 0 20px 36.8px 3.2px rgba(52, 59, 75, 0.17);
  border: 1px solid rgb(234, 240, 246);
  @include border-radius(14px);
  position: relative;

  h3 {
    font: 19px 'nunito-semibold';
    color: #4a5163;
    margin: 0;
  }
  p {
    font: 13px 'nunito-regular';
    color: #4a5163;
    margin: 0;
  }
  table {
    width: 100%;
    margin-top: 7px;
    margin-left: -2px;

    td {
      font: 12px 'nunito-semibold';
      color: #4a5163;
      padding-left: 0;
    }
    label {
      color: #5b7a90;
      font: 12px 'nunito-bold';
      margin-right: 5px;
    }
    a {
      color: #019ae6;
    }
  }
  b {
    font-weight: normal;
    color: #3b3838;
  }
}

.employee-info {
  padding: 18px;
  border-bottom: 2px solid #d3edf7;
  .img-employee {
    width: 75px;
    height: 75px;
    border: 1px solid #f3f4f8;
    overflow: hidden;
    position: relative;
    @include border-radius(50%);
    display: inline-block;
    vertical-align: top;
    img {
      @extend .vertical-align;
      width: 90px;
    }
  }
}

.content-employee {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 80px);
  padding-left: 20px;
}

.company-info {
  padding: 25px 18px 20px;
}

.content-company {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 65px);
  padding-left: 15px;
}

.img-company {
  width: 58px;
  height: 58px;
  display: inline-block;
  vertical-align: top;
  border: 1px solid rgb(242, 243, 247);
  @include border-radius(4px);
  text-align: center;
  line-height: 56px;
  text-align: center;
  img {
    vertical-align: middle;
    max-width: 100%;
    max-height: 100%;
  }
}

// .btn {
//     flex: 0 0 auto;
//     text-transform: uppercase;
//     color: #fff;
//     border: 0;
//     @include border-radius(6px);
//     box-shadow: 0 10px 23.75px 1.25px rgba(52, 59, 75, 0.12);
//     padding: 0 24px;
//     font: 14px 'nunito-bold';
// }

// .btn-blue {
//   background-color: rgb(0, 165, 254);
// }

.btn-block {
  width: 100%;
}

.btn-svg {
  &:hover {
    svg {
      right: 10px;
    }
  }
  svg {
    fill: #fff;
    width: 13px;
    height: 12px;
    margin-top: -6px;
    position: absolute;
    top: 50%;
    right: 15px;
    @include transition(0.1s);
  }
}

.head-form {
  display: flex;
  margin-top: 40px;
  input[type='email'] {
    flex: 1 0 8em;
    border: 1px solid rgb(234, 240, 246);
    box-shadow: 1px 1.732px 7px 0 rgba(69, 83, 94, 0.12);
    background-color: #fff;
    height: 45px;
    padding: 0 15px;
    margin-right: 8px;
    font: 14px 'nunito-semibold';
    color: #455064;
    @include border-radius(6px);
    @include transition(all 0.2s ease);
    @include placeholder {
      color: #849198;
    }
    &.error {
      color: #ff471e;
      @include placeholder {
        color: #ff471e;
      }
    }
  }
  button[type='submit'] {
    flex: 0 0 auto;
    text-transform: uppercase;
    color: #fff;
    border: 0;
    @include border-radius(6px);
    background-color: rgb(0, 165, 254);
    box-shadow: 0 10px 23.75px 1.25px rgba(52, 59, 75, 0.12);
    padding: 0 24px;
    font: 14px 'nunito-bold';
    transition: 0.3s ease;

    &:hover {
      box-shadow: 0 15px 32.8px 0 rgba(52, 59, 75, 0.22);
    }
  }
}

.platform-overview {
  background: url('../img/overview-bg.jpg') no-repeat center;
  -webkit-background-size: cover;
  background-size: cover;
  padding: 75px 0;
  @extend %clearfix;
  h2,
  h4 {
    color: #fff;
    text-align: center;
  }
  h2 {
    font: 44px 'nunito-semibold';
    margin: 0 0 20px;
  }
  h4 {
    font: 22px 'nunito-semibold';
    margin: 0 0 35px;
  }
  #left-platform,
  #right-platform {
    background: #fff;
    @include border-radius(14px);
    box-shadow: 0 20px 36.8px 3.2px rgba(52, 59, 75, 0.17);
    position: relative;
    .white-bottom,
    .white-bottom:before,
    .white-bottom:after {
      position: absolute;
      left: 15px;
      right: 15px;
      border: 1px solid rgb(234, 240, 246);
      background-color: rgb(255, 255, 255);
      opacity: 0.8;
      height: 100%;
      bottom: -5px;
      @include border-radius(14px);
    }
    .white-bottom:before {
      content: '';
      opacity: 0.6;
    }
    .white-bottom:after {
      content: '';
      opacity: 0.4;
      left: 30px;
      right: 30px;
      bottom: -10px;
    }
  }
  .overview-left,
  .overview-right {
    background: #fff;
    position: relative;
    @include border-radius(14px);
    z-index: 1;
    height: 100%;
    padding: 0 15px;
  }
  .overview-left {
    padding: 30px 45px;
  }
  #left-platform {
    width: 41%;
    z-index: 1;
  }
  #right-platform {
    width: 55%;
    z-index: 0;
  }
  .overview-flex {
    display: flex;
    justify-content: space-between;
  }
}

#left-platform,
#right-platform {
  position: relative;
}

section {
  h2 {
    color: #003366;
    font: 44px 'nunito-semibold';
    text-align: center;
    margin: 0 0 15px;
  }
  h4 {
    text-align: center;
    color: #45535e;
    font: 22px 'nunito-semibold';
    margin: 0 0 15px;
  }
  p.title {
    color: #45535e;
    font: 20px 'nunito-regular';
    text-align: center;
  }
}

.partners {
  padding: 70px 0;
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
    margin: 40px 0 30px;
  }
  li {
    width: 16%;
    list-style: none;
    padding: 0 15px;
    margin-bottom: 50px;
    text-align: center;
    a {
      @extend .middle-align;
    }
    img {
      max-width: 100%;
      display: block;
    }
  }
  &.small-partners {
    padding: 40px 0 0;
    li {
      width: 12.5%;
    }
    h3 {
      font: 24px 'nunito-semibold';
      color: #003366;
      text-align: center;
    }
  }
}

.head-form.custom-head-form {
  max-width: 610px;
  margin: 30px auto 0;
  button[type='submit'] {
    padding: 0 50px;
    position: relative;
    &:hover {
      svg {
        right: 10px;
      }
    }
    svg {
      fill: #fff;
      width: 13px;
      height: 12px;
      margin-top: -6px;
      position: absolute;
      top: 50%;
      right: 15px;
      @include transition(0.1s);
    }
  }
}

.sep {
  height: 5px;
  width: 78px;
  background-color: rgb(0, 165, 254);
  margin: 20px auto;
  @include border-radius(2px);
}

.pattern-bg {
  background: url('../img/pattern.png') no-repeat right center;
  -webkit-background-size: contain;
  background-size: contain;
  &.pattern-bg-left {
    background: url('../img/pattern-2.png') no-repeat right center;
    background-position: left bottom;
  }
}

.form-group {
  position: relative;
  label {
    text-transform: uppercase;
    font: 12px 'nunito-bold';
    color: #003366;
    display: block;
    margin-bottom: 5px;
    span {
      color: #ff5548;
    }
    small {
      text-transform: uppercase;
      color: #8b98ab;
      margin-left: 3px;
    }
  }
}

.input-box {
  margin-bottom: 20px;
  position: relative;
  &.not-empty {
    .form-control {
      background-color: #fff;
      border-color: #009bef;
    }
    .left-icon {
      color: #009bef;
    }
  }
  .form-control {
    padding: 0 45px;
    height: 56px;
    &:focus {
      ~ .left-icon {
        color: #009bef;
      }
    }
  }
  .v-popover {
    width: 100%;
    > span {
      width: 100%;
    }
  }
  .left-icon {
    position: absolute;
    top: 50%;
    left: 15px;
    @include transform(translateY(-50%));
  }
  .right-icon {
    position: absolute;
    top: 50%;
    right: 15px;
    @include transform(translateY(-50%));
  }
  .icon-map {
    color: #43515c;
    font-size: 18px;
  }
  .icon-loop {
    color: #99a5b6;
  }
}

.form-control {
  height: 46px;
  background-color: #eaf0f6;
  border: 1px solid #dfe4ec;
  width: 100%;
  padding: 0 15px;
  font: 14px 'nunito-semibold';
  @include placeholder {
    color: #003366;
  }
  @include border-radius(4px);
  &:focus {
    background-color: #fff;
    border-color: #009bef;
  }
  &.has-error {
    border-color: #ff471e;
    box-shadow: 0 10px 23.75px 1.25px rgba(255, 71, 30, 0.12);
    background-color: #fff;
  }
}

textarea.form-control {
  height: 90px;
  padding: 12px 15px;
  resize: none;
}

.form-inline {
  @extend %clearfix;
  .form-group {
    width: 48%;
    &:first-child {
      float: left;
    }
    &:last-child {
      float: right;
    }
  }
}

.platform-normal {
  text-align: center;
  position: relative;
  top: 50%;
  max-width: 500px;
  margin: 0 auto;
  @include transform(translateY(-50%));
  img {
    display: block;
    margin: 0 auto 35px;
  }
  h3 {
    font: 24px 'nunito-semibold';
    color: #003366;
    margin: 0 0 18px;
  }
  p {
    font: 16px 'nunito-semibold';
    color: #45535e;
  }
}

.custom-dropdown {
  max-height: 140px;
  ul {
    padding: 0 1px;
    margin: 0;
    li {
      list-style: none;
      color: #191919;
      font: 14px 'nunito-regular';
      position: relative;
      padding: 5px 30px;
      cursor: pointer;
      @include transition(0.2s);
      span {
        position: absolute;
        top: 50%;
        left: 10px;
        color: #31c172;
        font-size: 9px;
        opacity: 0;
        @include transform(translateY(-50%));
      }
      &:hover:not('.active') {
        background-color: #cbd6e2;
      }
      &.active {
        color: #009bef;
        span {
          opacity: 1;
        }
      }
    }
  }
}

blockquote {
  border: 1px dashed #d3edf7;
  margin: 0;
  padding: 12px 15px;
  @include border-radius(4px);
  p {
    width: calc(100% - 35px);
    max-width: 250px;
    line-height: 1.5;
    color: rgb(69, 83, 94);
    font: 16px 'nunito-semibold';
    display: inline-block;
    vertical-align: top;
    margin: 0;
  }
  span {
    margin-right: 12px;
    display: inline-block;
    vertical-align: top;
    margin-top: 3px;
    color: #028afe;
  }
}

.tooltip.popover {
  width: 100%;
  z-index: 2;
}

.custom-dropdown ul {
  background-color: #fff;
  border: 1px solid #dfe4ec;
}
.custom-bar {
  background-color: rgba(165, 208, 235, 0.251) !important;
  border-radius: 2px;
  .bar--vertical-internal {
    background: #2eadfd !important;
  }
}

.vue-scrollbar__scrollbar-vertical .scrollbar {
  background: #2eadfd !important;
  width: 3px !important;
  margin-left: 1px;
}

.vue-scrollbar__scrollbar-vertical {
  background-color: rgba(165, 208, 235, 0.251) !important;
  border-radius: 2px;
  width: 5px !important;
}
.right-normal {
  padding: 35px 35px 40px;
  animation: fade-in 1s forwards;
}

.normal-header {
  min-height: 25.33px;

  @extend %clearfix;
  span,
  p {
    float: left;
  }
  span {
    color: rgb(43, 152, 209);
    margin: 2px 5px 0 0;
  }
  p {
    font: 16px 'nunito-semibold';
    color: #45535e;
    margin: 0;
  }
  b {
    float: right;
    color: #003366;
    font: 18px 'nunito-bold';
    animation: fade-in 0.5s forwards;
  }
}

.tag {
  color: #fff;
  font: 10px 'nunito-extrabold';
  padding: 7px 8px 7px 10px;
  @include border-radius(3px);
  @extend .middle-align;
  margin-left: 10px;
  margin-bottom: 2.5px;
  // background: #00a5fe;

  span {
    margin-left: 7px;
  }
}

.platform-item {
  margin-top: 15px;
  @extend %clearfix;
  label {
    @extend .middle-align;
    text-transform: uppercase;
    font: 12px 'nunito-bold';
    color: #003366;
  }
  .total-items {
    float: right;
    font: 13px 'nunito-bold';
    color: #33475b;
    white-space: nowrap;
    margin-top: 7px;
    span {
      color: #00a5fe;
      font-size: 13px;
      @extend .middle-align;
      margin-left: 3px;
    }
  }
}

.check-list {
  li {
    list-style: none;
    padding: 0 15px;
    position: relative;
    font: 14px 'nunito-semibold';
    color: #003366;
    margin-bottom: 10px;
    &:before {
      position: absolute;
      left: 0;
      content: '';
      top: 3px;
      background: url('../img/check.svg') no-repeat center;
      width: 10px;
      height: 10px;
    }
  }
}

.normal-info {
  margin-top: 25px;
  .btn-transparent,
  .btn-blue {
    min-width: 210px;
    max-width: 50%;
  }
  .btn-blue {
    margin-right: 10px;
  }
  h3 {
    color: #003366;
    font: 18px 'nunito-bold';
    margin: 0;
  }
  .check-list {
    column-count: 2;
    margin: 20px 0;
  }
}

.platform-demo {
  animation: fade-in 1s forwards;

  @extend %clearfix;
  padding: 40px 14%;
  .form-group {
    margin-bottom: 12px;
  }
  h3 {
    font: 24px 'nunito-semibold';
    color: #003366;
    margin: 0 0 15px;
    b {
      cursor: pointer;
      float: right;
      font: 13px 'nunito-bold';
      color: #33475b;
      margin: 7px 0;
      span {
        color: #3bbafe;
        margin-left: 5px;
      }
    }
  }
  p {
    font: 16px 'nunito-semibold';
    color: #45535e;
  }
}

.book-demo-success {
  @include width(250px);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;

  h2 {
    color: #000;
  }

  p {
    text-align: center;
    font: 16px 'nunito-semibold';
    color: #45535e;
  }
}

.btn-blue.btn-with-icon {
  min-width: 215px;
  max-width: 100%;
  position: relative;
  margin-top: 20px;
  &:hover span,
  &:hover svg {
    right: 10px;
  }
  span,
  svg {
    width: 13px;
    height: 12px;
    margin-top: -6px;
    position: absolute;
    top: 50%;
    right: 15px;
    transition: all 0.1s ease;
  }
  span {
    @include transform(rotate(-90deg));
  }
  svg {
    fill: #fff;
  }
}

// Api page
@keyframes rotating {
  from {
    transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

.rotating {
  animation: rotating 2s linear infinite;
}

.api-gear {
  animation: rotating 2s linear infinite;
  left: 27.5% !important;
  top: 27.5% !important;
}

.api {
  padding: 160px 0 0;
  background: url('../img/pattern.png') no-repeat right top 100px;
  .api-element {
    margin-top: 50px;
    @extend %clearfix;
  }
  .api-element-left {
    float: left;
    width: 48%;
    h3 {
      font: 44px 'nunito-semibold';
      color: #003366;
      margin: 0;
    }
    .sep {
      margin: 20px 0;
    }
    p {
      font: 22px 'nunito-semibold';
      color: #45535e;
      line-height: 1.2;
    }
    h5 {
      font: 20px 'nunito-regular';
      color: #45535e;
      line-height: 1.2;
    }
  }
  .api-element-right {
    float: right;
    width: 48%;
    text-align: center;
    img {
      max-width: 100%;
    }
  }
}

//Pricing page
.pricing-form {
  position: relative;
  width: 740px;
  max-width: 95%;
  margin: 40px auto 0;
  background-color: #fff;
  border: 1px solid rgb(234, 240, 246);
  box-shadow: 0 17px 44px 6px rgba(52, 59, 75, 0.12);
  @include border-radius(14px);
  padding: 25px 35px;
  .form-group {
    margin-top: 12px;
  }
}

#pricing-success {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  z-index: 1;
  background-color: #fff;
  border-radius: 14px;
}

.pricing-success {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @include width(300px);

  p {
    font: 16px 'nunito-semibold';
  }
}

.form-finish {
  position: relative;
}

.form-finish-block {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  z-index: 1;
  background-color: #fff;

  .form-thanks {
    text-align: center !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include width(300px);

    h2 {
      text-align: center;
    }

    p {
      font: 16px 'nunito-semibold';
    }

    button {
      margin-top: 0;
    }
  }
}

// Our data page
.our-data {
  font-size: 0;
  background: #2a6ab4;
  background: -moz-linear-gradient(left, #2a6ab4 0%, #39b7c9 100%);
  background: -webkit-linear-gradient(left, #2a6ab4 0%, #39b7c9 100%);
  background: linear-gradient(to right, #2a6ab4 0%, #39b7c9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2a6ab4', endColorstr='#39b7c9', GradientType=1);
  padding: 135px 0 70px;
  .data-left,
  .data-right {
    @extend .middle-align;
    width: 50%;
  }
  .data-left {
    color: #fff;
    h1 {
      font: 60px 'nunito-semibold';
      margin: 0 0 20px;
    }
    h3 {
      font: 30px 'nunito-regular';
      margin: 0 0 35px;
    }
  }
  .data-right {
    text-align: right;
  }
}

.i-countries {
  fill: url(#linear-gradient1);
}

.i-envelope {
  fill: url(#linear-gradient2);
}

.i-dials {
  fill: url(#linear-gradient3);
}

.i-companies {
  fill: url(#linear-gradient);
}

.data-circle {
  width: 400px;
  height: 400px;
  position: relative;
  background: url('../img/lifecycle.svg') no-repeat center;
  -webkit-background-size: contain;
  background-size: contain;
  display: inline-block;
  li {
    @include border-radius(50%);
    background-color: #fff;
    width: 72px;
    height: 72px;
    position: relative;
    svg {
      @extend .vertical-align;
    }
    span {
      color: #fff;
      font: 11px 'nunito-bold';
      text-transform: uppercase;
      background-color: rgba(52, 59, 75, 0.749);
      @include border-radius(4px);
      padding: 5px 8px;
      white-space: nowrap;
      position: absolute;
      box-shadow: 0 10px 23.75px 1.25px rgba(52, 59, 75, 0.12);
      left: 50%;
      @include transform(translateX(-50%));
      &.place-top {
        top: -42px;
      }
      &.place-bottom {
        bottom: -42px;
      }
    }
    &:first-child {
      position: absolute;
      left: 33.25%;
      top: 7.5%;
    }
    &:nth-child(2) {
      position: absolute;
      right: 7.5%;
      top: 33.75%;
    }
    &:nth-child(3) {
      position: absolute;
      right: 33.25%;
      bottom: 7.5%;
    }
    &:nth-child(4) {
      position: absolute;
      bottom: 33.25%;
      left: 7.5%;
    }
  }
}

.collect-data {
  padding: 60px 0;
}

.collect-list {
  display: flex;
  justify-content: space-between;
  background: url('../img/flow.png') no-repeat center top;
  background-size: 75%;
  margin-top: 45px;
  li {
    text-align: center;
    list-style: none;
  }
  .box-icon {
    border: 1px dashed rgb(96, 197, 253);
    width: 138px;
    background-color: #fff;
    height: 138px;
    position: relative;
    @include border-radius(50%);
    display: inline-block;
    img {
      @extend .vertical-align;
    }
  }
  p {
    font: 20px 'nunito-semibold';
    color: #003366;
    padding: 0 15px;
    margin: 25px 0 0;
  }
}

.validate-data {
  background-color: #f6f7fb;
  padding: 60px 0 70px;
  h5 {
    font: 18px 'nunito-semibold';
    color: #45535e;
    text-align: center;
    margin: 7px 0 0;
  }
  table {
    table-layout: fixed;
    width: 100%;
    margin: 25px 35px 0;
    border-collapse: collapse;
    td {
      border: 1px solid #dfe4ec;
      color: #003366;
      padding: 50px;
      font-size: 0;
    }
  }
  table tr:first-child td {
    border-top: 0;
  }
  table tr:last-child td {
    border-bottom: 0;
  }
  table tr td:first-child {
    border-left: 0;
  }
  table tr td:last-child {
    border-right: 0;
  }
  .validate-icon {
    display: inline-block;
    vertical-align: top;
    width: 110px;
    img {
      max-width: 100%;
    }
  }
  p {
    display: inline-block;
    vertical-align: top;
    font: 23px 'nunito-semibold';
    width: calc(100% - 110px);
    margin: 0;
  }
}

// Platform page
.checko-list {
  margin-top: 30px;
  li {
    list-style: none;
    font: 20px 'nunito-semibold';
    color: #45535e;
    margin: 0 0 15px;
    padding: 0 0 0 35px;
    position: relative;
    &:before {
      content: '';
      width: 24px;
      height: 24px;
      background: url('../img/check-white.svg') no-repeat center #003366;
      left: 0;
      top: 1px;
      position: absolute;
      @include border-radius(50%);
    }
  }
}

// Accordion

.accordion-group {
  max-width: 825px;
  margin: 0 auto;
}

.accordion-header {
  background-color: #fff;
  color: #003366;
  font: 20px 'nunito-semibold';
  cursor: pointer;
  text-transform: uppercase;
  padding: 15px 45px 15px 30px;
  position: relative;
  &:hover {
    color: #009bef;
    &:after {
      border: solid #009bef;
      border-width: 0 1px 1px 0;
    }
  }
  &:after {
    content: '';
    border: solid #99a4b5;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 5px;
    position: absolute;
    top: 50%;
    margin-top: -7px;
    right: 30px;
    -webkit-transform-origin: center;
    -moz-transform-origin: center;
    -ms-transform-origin: center;
    -o-transform-origin: center;
    transform-origin: center;
    @include transform(rotate(45deg));
  }
  &.active:after {
    @include transform(rotate(-135deg));
  }
}

.accordion {
  border-bottom: 1px solid #f2f5f8;
}

.accordion-panel {
  background-color: #fff;
  display: none;
  padding: 0 30px 20px;
  overflow: hidden;
}

.accordion-panel.show {
  display: block;
}

.custom-content {
  padding-bottom: 70px;
  ol {
    padding-left: 20px;
    li {
      color: #191919;
      font: 16px 'nunito-regular';
      line-height: 1.5;
      padding: 0 0 0 20px;
      a {
        color: #00a5fe;
      }
    }
  }
}

// Contact us page
.contact-us {
  background: #777cf5;
  background: -moz-linear-gradient(left, #777cf5 0%, #71a8f5 100%);
  background: -webkit-linear-gradient(left, #777cf5 0%, #71a8f5 100%);
  background: linear-gradient(to right, #777cf5 0%, #71a8f5 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#777cf5', endColorstr='#71a8f5', GradientType=1);
  -webkit-background-size: cover;
  background-size: cover;
  padding: 150px 0 300px;
  h1 {
    color: #fff;
    font: 60px 'nunito-semibold';
    text-align: center;
    margin: 0 0 60px;
    position: relative;
    z-index: 1;
  }
}

.contact-form {
  position: relative;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 17px 44px 6px rgba(52, 59, 75, 0.12);
  border: 1px solid rgb(234, 240, 246);
  margin: -300px auto 70px;
  @include border-radius(14px);
  padding: 6.2% 6.7%;
  font-size: 0;
  max-width: 90%;
  .form-group {
    margin-bottom: 12px;
  }
  .col-h {
    @extend .middle-align;
    width: 50%;
  }
  h2 {
    text-align: left;
  }
  .sep {
    margin: 20px 0;
  }
  h5 {
    font: 14px 'nunito-bold';
    color: #003366;
    text-transform: uppercase;
    margin: 0 0 15px;
  }
  p {
    font: 18px 'nunito-semibold';
    color: #191919;
    margin: 0 0 20px;
  }
  a {
    color: #00a5fe;
  }
}

.socials-o {
  a {
    width: 42px;
    height: 42px;
    background-color: #fff;
    border: 1px solid #d4edf7;
    position: relative;
    box-shadow: 1px 1.732px 7px 0 rgba(69, 83, 94, 0.12);
    @include transition(0.2s);
    @include border-radius(50%);
    @extend .middle-align;
    &:not(:last-child) {
      margin-right: 10px;
    }
    svg {
      @extend .vertical-align;
      @include transition(0.2s);
    }
    &.fb-o {
      svg {
        fill: #3c5998;
      }
      &:hover {
        background-color: #3c5998;
        border-color: #3c5998;
        svg {
          fill: #fff;
        }
      }
    }
    &.tw-o {
      svg {
        fill: #00a0fe;
      }
      &:hover {
        background-color: #00a0fe;
        border-color: #00a0fe;
        svg {
          fill: #fff;
        }
      }
    }
    &.in-o {
      svg {
        fill: #017bb5;
      }
      &:hover {
        background-color: #017bb5;
        border-color: #017bb5;
        svg {
          fill: #fff;
        }
      }
    }
    &.gp-o {
      svg {
        fill: #dd4b39;
      }
      &:hover {
        background-color: #dd4b39;
        border-color: #dd4b39;
        svg {
          fill: #fff;
        }
      }
    }
  }
}

.contact-pattern {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../img/pattern-white.png') no-repeat 0% 0%;
    //background-size: contain;
  }
}

//Customers page
.feedback {
  padding: 50px 0;
}

.feeback-list {
  display: flex;
  margin-top: 40px;
  justify-content: space-between;
  li {
    width: 31.5%;
    list-style: none;
    .feed-content {
      background-color: #f6f7fb;
      font: 23px 'nunito-italic';
      border: 1px dashed #60c5fd;
      padding: 30px 25px;
      line-height: 1.565;
      @include border-radius(6px);
      margin-bottom: 40px;
      position: relative;
      &:after {
        content: '';
        border: dashed #60c5fd;
        border-width: 0 1px 1px 0;
        padding: 5px;
        position: absolute;
        top: 100%;
        left: 20px;
        margin-top: -4px;
        background-color: #f6f7fb;
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
    .img-person {
      width: 66px;
      height: 66px;
      @include border-radius(50%);
      overflow: hidden;
      position: relative;
      border: 1px solid rgb(234, 240, 246);
      background-color: #fff;
      margin-bottom: 20px;
      box-shadow: 0 10px 23.75px 1.25px rgba(52, 59, 75, 0.12);
      img {
        @extend .vertical-align;
        max-width: 110%;
        max-height: 110%;
      }
    }
    .in-details {
      color: #007bb5;
      margin-bottom: 7px;
      font: 20px 'nunito-semibold';
      svg {
        width: 20px;
        height: 20px;
        fill: #007bb5;
        @extend .middle-align;
        margin: 0 7px 3px 0;
      }
    }
    p {
      color: #45535e;
      font: 15px 'nunito-semibold';
      margin: 0 0 20px;
    }
    .company-details {
      img {
        max-width: 100%;
      }
    }
  }
}

.customers {
  padding-top: 160px;
  overflow: hidden;
  h4 {
    font: 34px 'nunito-semibold';
    color: #003366;
    margin: 0 0 30px;
  }
  h3 {
    font: 52px 'nunito-light';
    color: #0089fe;
    text-align: center;
    margin: 0;
    margin-bottom: 60px;
  }
}

.IconsContainer {
  width: 3000px;
  height: 500px;
  position: relative;
  z-index: 1;
  > img {
    opacity: 0;
  }
}

.Icon {
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  @include border-radius(50%);
  -webkit-box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1px);
  -moz-box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1px);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1px);
  position: absolute;
  will-change: transform, opacity;
  background: #fff;
  overflow: hidden;
  img {
    @extend .vertical-align;
    // max-width: 90%;
    // max-height: 90%;
  }
  span {
    position: absolute;
    will-change: transform, opacity;
  }
}

// SIGN UP
#signup {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  animation: fade-in 0.5s forwards;
  overflow: auto;
}

.signup-close {
  font-size: 35px;
  margin-top: 4px;
  cursor: pointer;

  transition: 0.3s ease;

  &:hover {
    color: #009bef;
  }
}

.signup-block {
  @include width(382.5px);
  margin: 0 auto;
  margin-top: 120px;
  text-align: center;

  h2 {
    font: 36px 'nunito-semibold';
    color: #003366;
    margin-bottom: 0;
  }

  p {
    font: 16px 'nunito-semibold';
    color: #45535e;
  }

  .red {
    color: #ff5548;
  }

  > form {
    text-align: left;

    > label {
      display: block;
      width: 100%;
      font: 12px 'nunito-bold';
      position: relative;
      margin-top: 7.5px;

      > small {
        position: absolute;
        right: 10px;
        top: 0;
        color: #45535e;
      }

      input {
        display: block;
        width: 100%;
        font: 14px 'nunito-semibold';
        background-color: #eaf0f6;
        @include placeholder-color(#6f747e);
        color: #000;
        border: 1px solid #dfe4ec;
        border-radius: 3px;
        padding: 12.5px;
        margin: 5px 0;
        transition: 0.3s ease;

        &:focus,
        &:hover {
          background-color: #ffffff;
        }

        &:hover {
          border-color: #009bef;
          box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
}

.inpt-error {
  background-color: #ffffff !important;
  border-color: #ff471e !important;
  box-shadow: 0px 0px 5px 0px rgba(255, 0, 0, 0.3) !important;
}

.blue-line {
  height: 2px;
  width: 70px;
  margin: 5px auto;
  background-color: #00a5fe;
}

.signup-terms {
  color: #45535e !important;
  font: 12px 'nunito-regular' !important;
  text-align: left !important;

  > a {
    color: #009bef !important;
  }
}

.modal-below {
  text-align: center;

  h3 {
    font: 23px 'nunito-semibold';
    color: #003366;
    margin-bottom: 20px;
  }

  .below-img {
    margin: 20px 0;

    img {
      width: 7%;
      margin: 0 20px;
      vertical-align: middle;
      filter: grayscale(100%);
      opacity: 0.5;
    }
  }
}

#signup-2 {
  width: 235px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  h2 {
    font: 36px 'nunito-semibold';
    color: #003366;
  }

  p {
    font: 16px 'nunito-semibold';
    color: #45535e;
  }
}

.form-item {
  text-align: left;
  display: block;
  width: 100%;
  font: 12px 'nunito-bold';
  position: relative;
  margin-top: 7.5px;

  > small {
    position: absolute;
    right: 0;
    top: 0;
    color: #ff471e;
    background: #fffc;
    padding-left: 5px;
  }

  input {
    display: block;
    width: 100%;
    font: 14px 'nunito-semibold';
    background-color: #eaf0f6;
    @include placeholder-color(#6f747e);
    color: #000;
    border: 1px solid #dfe4ec;
    border-radius: 3px;
    padding: 0 12.5px;
    height: 46px;
    margin: 5px 0;
    transition: 0.3s ease;

    &:focus,
    &:hover {
      background-color: #ffffff;
    }

    &:hover {
      border-color: #009bef;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    }
  }

  .inpt-error {
    background-color: #ffffff;
    border-color: #ff471e;
    box-shadow: 0px 0px 5px 0px rgba(255, 0, 0, 0.3);
  }
}

.form-half {
  .form-item {
    float: left;
    width: 50%;

    &:last-child {
      margin-left: 15px;
      width: calc(50% - 15px);
    }
  }

  &:after {
    clear: both;
    content: '';
    display: block;
  }
}

// BOOK-A-DEMO
@keyframes fade-in-pulse-08 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  50% {
    opacity: 1;
  }

  100%,
  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

#book-demo,
.download-sample {
  overflow: auto;
  z-index: 4;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  animation: fade-in 0.5s forwards;
  text-align: center;
}

.download-sample {
  left: 0;
  top: 0;
}

.book-demo {
  position: relative;
  animation: fade-in-pulse-08 0.3s forwards cubic-bezier(0.8, 0.02, 0.45, 0.91);
  display: inline-block;
  margin: 110px 0;
  //@include width(520px);
  width: 520px;
  max-width: 95%;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 20px 36.8px 3.2px rgba(52, 59, 75, 0.17);
  max-height: 470px;
  border: 1px solid rgb(234, 240, 246);
  border-radius: 14px;
  padding: 30px 40px;

  h2 {
    font: 32px 'nunito-semibold';
    color: #003366;
    margin: 0;
  }

  p {
    font: 16px 'nunito-semibold';
    color: #45535e;
  }

  button {
    margin-bottom: 5px;
  }

  .book-close {
    position: absolute;
    right: -35px;
    top: -18px;
    font-size: 45px;
    color: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    transition: 0.3s ease;

    &:hover {
      color: hsla(201, 100%, 47%, 0.8);
    }
  }
}

// MENU
#menu-panel {
  position: fixed;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.301);
  height: 100%;
  width: 100%;
  animation: fade-in 0.5s forwards;

  .menu-panel {
    padding-top: 75px;
    position: absolute;
    @include width(400px);
    height: 100%;
    background-color: #1c2a37;
    z-index: 10;
    right: -100%;
    top: 0;
    animation: fade-right 1s forwards;
    overflow: auto;

    .menu-close {
      position: absolute;
      right: 25px;
      top: 15px;
      font-size: 40px;
      color: #fff;
      cursor: pointer;
    }

    .menu-active {
      border-left: 5px solid #2997fe;
    }

    ul {
      a li {
        border-bottom: solid 1px #3f4f5e;
        display: block;
        position: relative;
        font: 16px 'nunito-semibold';
        padding: 15px 30px;
        color: rgba(255, 255, 255, 0.9);
      }
    }
  }
}

.small-error {
  font: 10px 'nunito-semibold';
  position: absolute;
  right: 10px;
  top: 0;
  color: #ff471e;
  background-color: rgba(255, 255, 255, 0.8);
  padding-left: 5px;
}

.platform-control {
  position: relative;

  .platform-control-left {
    position: absolute;
    left: 15px;
    top: 20px;
    z-index: 1;
  }

  .platform-control-right {
    position: absolute;
    right: 15px;
    top: 20px;
    z-index: 1;
  }
}

.with-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.with-scroll::-webkit-scrollbar {
  width: 4px;
  background-color: #ffffff;
}

.with-scroll::-webkit-scrollbar-thumb {
  background-color: #2eabfd;
  border-radius: 2px;
}
