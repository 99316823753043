.container,.wrap {
  margin-right: auto;
  margin-left: auto;
  /* padding-left: 7.5px;
  padding-right: 7.5px; */
}
// @media (min-width: 768px) {
//   .container {
//     width: 735px;
//   }
// }
// @media (min-width: 992px) {
//   .container {
//     width: 955px;
//   }
// }
@media (min-width: 1280px) {
  .container {
    width: 1280px;
  }
  .wrap{
    width: 1110px;
  }
}
.clearfix,
.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after {
  content: ' ';
  display: table;
}
.clearfix:after,
.container:after,
.container-fluid:after,
.row:after {
  clear: both;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.relative {
  position: relative;
}

.cursor {
  cursor: pointer;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}
