@import './grid.scss';
@import './main.scss';
@import './icons.css';

@media (max-width: 1440px) {
  // CHECK LIST
  .checko-list {
    li {
      font-size: 16px;
    }
  }
  // CUSTOMERS
  .customers {
    h4 {
      margin: 0 0 15px;
    }

    h3 {
      font-size: 48px;
    }

    h2 {
      font-size: 32px;
    }
  }

  .feeback-list {
    li .feed-content {
      font-size: 17px;
      margin-bottom: 30px;
    }
  }

  // GRID
  .container, .wrap {
    //width: calc(100% - 20px);
    max-width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }

  section {
    h2 {
      font-size: 40px;
    }

    p.title {
      font-size: 17px;
      margin-top: 5px;
    }
  }
  .copyright {
    padding: 40px 0;
  }
  .sep {
    margin: 10px auto;
    height: 2px;
  }
  footer {
    padding-top: 65px;
  }
  // OUR-DATA
  .validate-data table {
    margin: 25px 0 0;
  }

  .data-circle {
    width: 350px;
    height: 350px;

    li span {
      font-size: 9px;
    }
  }

  // API

  .api-gear {
    left: 32.5% !important;
    top: 22.5% !important;
  }

  .api .api-element-left {
    font-size: 37px;

    h3 {
      font-size: 37px;
    }

    p {
      font-size: 18px;
      margin: 0;
    }

    h5 {
      font-size: 14px;
      margin: 5px 0;
    }

    .btn-double {
      .link-icon {
        height: 42px;

        svg {
          width: 17.5px;
        }
      }

      .link-content {
        line-height: 42px;
      }
    }
  }

  // HOME
  .home-head {
    .home-head-left {
      h1 {
        font-size: 36px;
        margin: 0 0 15px;
      }

      p {
        font-size: 17px;
      }

      .head-form {
        margin-top: 25px;
      }
    }
    .home-head-right {
      margin-bottom: 40px;
    }
  }

  .platform-overview {
    padding: 50px 0;

    h2 {
      font-size: 36px;
      margin: 0 0 10px;
    }

    h4 {
      font-size: 18px;
      margin: 0 0 25px;
    }
  }
  .employee-info .img-employee {
    width: 60px;
    height: 60px;
  }
  .content-employee {
    width: calc(100% - 65px);
    padding-left: 10px;
  }
  .home-profile {
    h3 {
      font-size: 18px;
    }
    p {
      font-size: 12px;
    }
  }
  .home-profile table td {
    font-size: 11px;
  }
  .employee-info {
    padding: 12px 15px;
  }
  .company-info {
    padding: 15px;
  }
  .home-head .home-head-right .home-shadow {
    left: 5%;
    top: 10%;
  }
  .home-head {
    padding: 150px 0 60px;
  }
  nav li a {
    font-size: 16px;
    padding: 12px 0;
  }
  header {
    padding: 20px 0;
  }
  header .home-link {
    width: 160px;
    height: 43px;
  }
  blockquote p {
    font-size: 15px;
  }
  .platform-normal h3 {
    font-size: 23px;
  }
  .platform-normal p {
    font-size: 15px;
  }
  .feeback-list li p {
    font-size: 14px;
  }
  .feeback-list li .in-details {
    font-size: 18px;
    margin: 0 0 15px;
  }
  .feedback {
    padding: 40px 0 20px;
  }
  .our-data .data-left h1 {
    font-size: 55px;
  }
  .validate-data h5 {
    font-size: 17px;
  }
  .our-data .data-left h3 {
    font-size: 28px;
  }
  .validate-data table td {
    padding: 40px;
  }
  .validate-data {
    padding: 50px 0 60px;
  }
  .validate-data .validate-icon {
    width: 100px;
    img {
      max-width: 90%;
    }
  }
  .validate-data p {
    font-size: 20px;
  }
  .collect-list p {
    font-size: 18px;
  }
  .our-data .data-right {
    text-align: center;
  }
  .contact-form {
    padding: 50px 60px;
  }
  .contact-form p {
    font-size: 17px;
  }
  .contact-form h5 {
    font-size: 13px;
  }
  .contact-us h1 {
    font-size: 55px;
  }
}

@media (max-width: 1280px) {
  .home-head .home-head-left h1 {
    font-size: 32px;
  }
  .home-head .home-head-left p {
    font-size: 16px;
  }
  .home-profile h3 {
    font-size: 17px;
  }
  .home-profile table {
    margin-top: 3px;
  }
  .employee-info .img-employee {
    width: 50px;
    height: 50px;
  }
  .content-employee {
    width: calc(100% - 55px);
    padding-left: 7px;
  }
  .img-company {
    width: 45px;
    height: 45px;
    line-height: 45px;
  }
  .home-profile table td {
    font-size: 10px;
  }
  .platform-overview h2 {
    font-size: 32px;
  }
  .platform-overview h4, .normal-info h3 {
    font-size: 17px;
  }
  .check-list li {
    font-size: 13px;
    margin-bottom: 7px;
  }
  .right-normal {
    padding: 25px 15px 30px;
  }
  .platform-overview .overview-left {
    padding: 30px;
  }
  .input-box .form-control {
    padding: 0 35px;
    height: 50px;
  }
  .input-box .left-icon {
    left: 12px;
    font-size: 14px;
  }
  blockquote {
    padding: 10px;
    p {
      font-size: 14px;
    }
  }
  .normal-info .btn-transparent, .normal-info .btn-blue {
    min-width: 190px;
  }
  .btn-transparent, .btn-blue {
    font-size: 13px;
  }
  section h2 {
    font-size: 36px;
  }
  .partners {
    padding: 55px 0;
  }
  .footer-logo img {
    max-width: 90%;
  }
  footer {
    padding-top: 50px;
  }
  footer h4 {
    font-size: 13px;
    margin: 0 0 15px;
  }
  .footer-nav a, footer p {
    font-size: 15px;
  }
  footer p {
    margin-bottom: 5px;
  }
  .copyright {
    padding: 30px 0;
  }
  .home-head .home-head-right, .home-head .home-head-left {
    width: 48%;
  }
  .home-head .home-head-left {
    padding-top: 20px;
  }
  .partners li {
    margin-bottom: 40px;
  }
  section h4 {
    font-size: 20px;
  }
  .head-form.custom-head-form button[type=submit] {
    padding: 0 40px;
  }
  .api .api-element-left h3 {
    font-size: 30px;
  }
  .checko-list li {
    font-size: 14px;
  }
  .api .api-element {
    margin-top: 30px;
  }
  .btn-double .link-content {
    width: 250px;
    font-size: 13px;
  }
  .partners ul {
    margin: 30px 0 20px;
  }
  .customers h4 {
    font-size: 28px;
  }
  .customers h3 {
    font-size: 44px;
  }
  .Icon {
    width: 120px;
    height: 120px;
  }
  .IconsContainer {
    height: 430px;
  }
  .feeback-list li .feed-content {
    font-size: 15px;
    margin-bottom: 25px;
    padding: 25px 20px;
  }
  .feeback-list li .img-person {
    width: 55px;
    height: 55px;
    margin-bottom: 15px;
  }
  .feeback-list li p {
    font-size: 13px;
    margin: 0 0 15px;
  }
  .feeback-list li .in-details {
    font-size: 16px;
  }
  .customers {
    padding-top: 140px;
  }
  .our-data .data-left h1 {
    font-size: 45px;
  }
  .our-data .data-left h3 {
    font-size: 25px;
  }
  .collect-list p {
    font-size: 16px;
  }
  .collect-list .box-icon {
    width: 130px;
    height: 130px;
    img {
      max-width: 60%;
      max-height: 60%;
    }
  }
  .validate-data {
    padding: 40px 0 50px;
  }
  .validate-data p {
    font-size: 18px;
  }
  .validate-data .validate-icon {
    width: 75px;
  }
  .validate-data table td {
    padding: 30px;
  }
  .validate-data .validate-icon img {
    max-width: 80%;
  }
  .contact-form {
    padding: 40px 50px;
  }
  .contact-us {
    padding: 110px 0 300px;
  }
  .contact-us h1 {
    font-size: 50px;
  }
  .accordion-header {
    font-size: 18px;
  }
  .custom-content ol li {
    font-size: 15px;
  }
  .signup-block h2 {
    font-size: 32px;
  }
  .book-demo h2 {
    font-size: 28px;
  }
  .form-item input {
    font-size: 13px;
    height: 42px;
  }
  .book-demo .book-close {
    right: -27px;
  }
}

@media (max-width: 1024px) {
  .signup-block h2 {
    font-size: 28px;
  }
  .signup-block p, .book-demo p {
    font-size: 15px;
  }
  .modal-below h3 {
    font-size: 20px;
  }
  .platform-demo {
    padding: 30px 7%;
  }

  // CONTACT US
  .contact-form {
    p {
      font-size: 14px;
    }
  }

  // NAV BAR
  .navbar-collapse {
    display: none;
  }

  .navbar-toggler {
    display: block;
  }

  // EMPLOYEE
  .home-head .home-head-right {
    .home-shadow {
      top: 5%;
      left: 4%;
    }
  }
  .home-head {
    padding: 110px 0 20px;
  }
  .home-head .home-head-left h1 {
    font-size: 28px;
  }
  .home-head .home-head-left p, .normal-header p {
    font-size: 15px;
  }
  .head-form button[type=submit] {
    padding: 0 15px;
    font-size: 12px;
  }
  .platform-overview .overview-left {
    padding: 20px;
  }
  .normal-header b {
    font-size: 16px;
  }
  section h2 {
    font-size: 32px;
    margin: 0 0 12px;
  }
  .partners li {
    padding: 0 13px;
    margin-bottom: 35px;
  }
  section h4 {
    font-size: 18px;
  }
  .footer-logo {
    width: 23%;
    img {
      max-width: 83%;
    }
  }
  .footer-nav {
    width: 25%;
  }
  .footer-call {
    width: 165px;
  }
  .footer-call .btn-transparent, .footer-call .btn-blue {
    width: 100%;
    min-width: 0;
  }
  .footer-contact {
    width: calc(52% - 165px);
  }
  .input-box .form-control {
    height: 46px;
    font-size: 12px;
  }
  .footer-nav a, footer p {
    font-size: 14px;
  }
  blockquote p, .custom-dropdown ul li {
    font-size: 12px;
  }
  footer .socials {
    margin-top: 25px;
  }
  .copyright {
    padding: 25px 0;
    font-size: 12px;
  }
  footer {
    padding-top: 40px;
  }
  .platform-normal h3 {
    font-size: 20px;
  }
  .platform-normal p {
    font-size: 13px;
  }
  .platform-normal img {
    margin: 0 auto 20px;
    max-height: 55px;
  }
  blockquote span {
    margin-right: 5px;
  }
  .right-normal {
    padding: 20px 12px 25px;
  }
  .employee-info {
    padding: 12px;
  }
  .platform-item label {
    font-size: 11px;
  }
  .check-list li {
    font-size: 12px;
    margin-bottom: 5px;
    padding: 0 5px 0 15px;
  }
  .normal-info .btn-transparent, .normal-info .btn-blue {
    min-width: 155px;
  }
  .platform-item {
    margin-top: 10px;
  }
  .normal-info {
    margin-top: 15px;
  }
  .btn-transparent, .btn-blue {
    font-size: 12px;
    height: 40px;
    line-height: 38px;
  }
  .platform-overview h4, .normal-info h3 {
    font-size: 15px;
  }
  .content-employee, .content-company {
    width: 100%;
    padding-left: 0;
  }
  .img-company, .employee-info .img-employee {
    display: none;
  }
  .home-profile h3 {
    font-size: 16px;
  }
  .home-profile p {
    font-size: 11px;
  }
  .head-form input[type=email] {
    height: 40px;
    padding: 0 12px;
    margin-right: 7px;
    font-size: 13px;
  }
  .company-info {
    padding: 12px;
  }

  .home-profile table td {
    padding: 0;
    padding-right: 5px;
  }
  .api {
    padding: 120px 0 0;
  }
  .checko-list li:before {
    width: 21px;
    height: 21px;
    border-radius: 50%;
  }
  .api .api-element-left p {
    font-size: 15px;
  }
  .api .api-element-left h5 {
    font-size: 13px;
    margin: 5px 0;
  }
  .api .api-element-left .btn-double .link-icon {
    height: 38px;
    width: 38px;
  }
  .api .api-element-left .btn-double .link-content {
    line-height: 38px;
    font-size: 12px;
    width: 225px;
    max-width: calc(100% - 45px);
  }
  .customers {
    padding-top: 120px;
  }
  .customers h4 {
    font-size: 24px;
  }
  .customers h3 {
    font-size: 37px;
  }
  .feeback-list li .feed-content {
    font-size: 14px;
    margin-bottom: 20px;
    padding: 20px 15px;
  }
  .feeback-list li .img-person {
    width: 45px;
    height: 45px;
    margin-bottom: 12px;
  }
  .partners {
    padding: 45px 0;
  }
  .btn-blue.btn-with-icon span, .btn-blue.btn-with-icon svg {
    width: 10px;
  }
  .pricing-form {
    width: 740px;
    max-width: 95%;
    margin: 30px auto 0;
    padding: 20px 30px;
  }
  .partners.small-partners h3 {
    font-size: 20px;
  }
  .partners.small-partners li {
    width: 21.5%;
  }
  .our-data .data-left h1 {
    font-size: 36px;
  }
  .our-data .data-left h3 {
    font-size: 18px;
  }
  .btn-white {
    font-size: 12px;
    padding: 0 45px;
    line-height: 45px;
  }
  .data-circle li span {
    font-size: 8px;
  }
  .collect-list .box-icon {
    width: 100px;
    height: 100px;
  }
  .collect-list p {
    font-size: 15px;
  }
  .collect-data {
    padding: 40px 0;
  }
  .validate-data h5 {
    font-size: 15px;
  }
  .validate-data p {
    font-size: 16px;
  }
  .validate-data p {
    width: calc(100% - 80px);
  }
  .validate-data table td {
    padding: 25px;
  }
  .validate-data {
    padding: 30px 0 40px;
  }
  .data-circle {
    width: 315px;
    height: 315px;
  }
  .data-circle li {
    width: 65px;
    height: 65px;
  }
  .data-circle li svg {
    width: 25px;
  }
  .data-circle li span.place-top {
    top: -32px;
  }
  .data-circle li span.place-bottom {
    bottom: -32px;
  }
  .contact-form {
    padding: 30px;
  }
  .contact-form h2 {
    margin-bottom: 0;
  }
  .contact-form h5 {
    font-size: 12px;
    margin-bottom: 10px;
  }
  .contact-us h1 {
    font-size: 45px;
    margin: 0 0 40px;
  }
  .accordion-header {
    font-size: 17px;
  }
  .custom-content ol li {
    font-size: 14px;
    padding: 0 0 0 12px;
  }
  .accordion-panel {
    padding: 0 25px 20px;
  }
  .accordion-header {
    padding: 12px 40px 12px 20px;
  }
  .accordion-header:after {
    right: 20px;
  }
}

@media (max-width: 768px) {
  .accordion-header:after {
    right: 15px;
  }
  .accordion-header {
    padding: 12px 35px 12px 20px;
  }
  .partners.small-partners h3 {
    font-size: 18px;
  }
  // PLATFORM
  .platform-overview {
    .overview-left {
      padding: 20px;
    }
  }

  blockquote p {
    font-size: 12px;
  }

  .our-data {
    .data-left {
      h1 {
        font-size: 35px;
      }

      h3 {
        font-size: 18px;
      }
    }
  }

  .right-normal {
    padding: 20px 0;
  }

  .input-box .form-control {
    padding: 0 45px;
    height: 40px;
  }
  footer h4 {
    font-size: 12px;
    margin: 0 0 12px;
  }
  .footer-nav a, footer p {
    font-size: 12px;
  }
  .footer-call {
    width: 150px;
  }
  .footer-contact {
    width: calc(52% - 155px);
  }
  footer .footer-flex {
    padding-bottom: 35px;
  }
  footer .socials a:not(:last-child) {
    margin-right: 5px;
  }
  .partners li {
    margin-bottom: 25px;
  }
  .tag {
    margin: 2.5px;
  }

  .normal-info {
    h3 {
      font-size: 16px;
    }

    li {
      font-size: 12px;
    }

  }
  .normal-info .btn-transparent, .normal-info .btn-blue {
    min-width: 100px;
    font-size: 11px;
    height: 35px;
    line-height: 33px;
  }
  .normal-header b {
    font-size: 15px;
  }
  .form-control {
    height: 40px;
  }
  .form-group label {
    font-size: 10px;
  }
  .btn-blue.btn-with-icon {
    min-width: 165px;
    margin-top: 5px;
  }
  section h2 {
    font-size: 30px;
  }
  .form-control, .platform-demo h3 b {
    font-size: 12px;
  }
  .platform-demo {
    padding: 25px 5%;
  }
  .platform-demo h3 {
    font-size: 20px;
  }
  .platform-demo p {
    font-size: 14px;
  }
  .platform-normal {
    h3 {
      font-size: 18px;
    }
    p {
      font-size: 13px;
    }
  }
  .api .api-element-left h3 {
    font-size: 24px;
  }
  .api .api-element-left .sep {
    margin: 15px 0;
  }
  .checko-list li {
    margin-bottom: 8px;
  }
  .home-head .home-head-left p, .normal-header p {
    font-size: 14px;
  }
  .home-head .home-head-left h1 {
    font-size: 25px;
  }
  .platform-overview h2 {
    font-size: 28px;
  }
  .platform-overview h4, .normal-info h3 {
    font-size: 14px;
  }
  // API

  // .api {
  //   //.api-element-left {
  //   //  width: 100%;
  //   //  float: unset;
  //   //}
  //   //
  //   //.api-element-right {
  //   //  display: none;
  //   //}
  // }

  .home-head .home-head-left {
    width: 100%;
    margin-right: 0;
  }

  .home-head .home-head-right {
    width: 100%;
    margin-top: 30px;
  }
  .img-company, .employee-info .img-employee {
    display: inline-block;
  }
  .content-employee {
    width: calc(100% - 55px);
    padding-left: 7px;
  }
  .content-company {
    width: calc(100% - 65px);
    padding-left: 15px;
  }
  .platform-overview {
    padding: 35px 0;
  }
  section p.title {
    font-size: 14px;
    margin-top: 5px;
  }
  .api .api-element-right, .api .api-element-left {
    width: 50%;
  }
  .api .api-element-left h3 {
    font-size: 20px;
  }
  section p.title {
    font-size: 12px;
    margin-top: 5px;
  }
  .api {
    padding: 100px 0 0;
  }
  .api .api-element-left .btn-double .link-icon {
    height: 35px;
    width: 35px;
  }
  .api .api-element-left .btn-double .link-content {
    line-height: 35px;
    font-size: 11px;
    width: 210px;
    max-width: calc(100% - 40px);
  }
  .api .api-element-left .btn-double .link-icon svg {
    width: 15px;
  }
  .partners ul {
    justify-content: space-around;
  }
  .customers h4 {
    font-size: 22px;
  }
  .customers h3 {
    font-size: 34px;
  }
  .IconsContainer {
    height: 400px;
  }
  .feeback-list li .feed-content {
    font-size: 13px;
    margin-bottom: 18px;
    padding: 15px 12px;
  }
  .feeback-list li .in-details {
    font-size: 14px;
  }
  .feeback-list li .in-details svg {
    width: 16px;
    height: 16px;
    margin: 0 3px 3px 0;
  }
  .feeback-list li p {
    font-size: 12px;
    margin: 0 0 12px;
  }
  .validate-data h5 {
    font-size: 13px;
  }
  .data-circle {
    width: 280px;
    height: 280px;
  }
  .data-circle li {
    width: 55px;
    height: 55px;
  }
  .data-circle li span {
    padding: 3px 6px;
  }
  .btn-white {
    font-size: 11px;
    padding: 0 40px;
    line-height: 37px;
  }
  .collect-list .box-icon {
    width: 80px;
    height: 80px;
  }
  .collect-list p {
    font-size: 13px;
    margin-top: 10px;
  }
  .validate-data table td {
    padding: 18px;
  }
  .validate-data p {
    font-size: 14px;
  }
  .validate-data .validate-icon {
    width: 60px;
  }
  .validate-data p {
    width: calc(100% - 70px);
  }
  .validate-data {
    padding: 20px 0 30px;
  }
  .our-data .wrap {
    display: flex;
    flex-direction: column;
  }
  .our-data .data-right {
    width: 100%;
    order: 1;
  }
  .our-data .data-left {
    width: 100%;
    text-align: center;
    order: 2;
    margin-top: 25px;
  }
  .our-data {
    padding: 110px 0 50px;
  }
  .our-data .wrap {
    &:after {
      clear: both;
      display: block;
      content: '';
    }
  }
  .contact-form .col-h {
    width: 100%;
  }
  .socials-o {
    margin-bottom: 30px;
  }
  .contact-us h1 {
    font-size: 40px;
    margin: 0 0 30px;
  }
  .accordion-header {
    font-size: 16px;
  }
  .custom-content ol li {
    font-size: 13px;
    padding: 0 0 0 10px;
  }
  .book-demo {
    width: 85%;
  }
  .signup-block p, .book-demo p {
    font-size: 13px;
  }
  .book-demo h2 {
    font-size: 22px;
  }
  .form-item input {
    font-size: 12px;
    height: 38px;
  }
  .signup-block h2 {
    font-size: 24px;
  }
  .signup-block p, .book-demo p {
    font-size: 12px;
  }
  .modal-below .below-img img {
    width: 8%;
    margin: 0 6px;
  }
  .modal-below h3 {
    font-size: 18px;
  }
}

@media (max-width: 639px) {
  .accordion-panel {
    padding: 0 15px 15px;
  }
  .partners.small-partners h3 {
    font-size: 17px;
  }
  // PLATFORM
  .partners li {
    width: 22%;
  }

  .platform-overview .overview-flex {
    display: block;

    #left-platform, #right-platform {
      width: 100%;
      display: block;
      margin-bottom: 30px;
    }
  }

  .platform-normal {
    position: unset;
    padding: 20px 0;
    transform: translateY(0%);
  }
  .home-head .home-head-left h1 {
    font-size: 22px;
  }
  .home-head .home-head-left p, .normal-header p {
    font-size: 13px;
  }
  .employee-info .img-employee, .img-company {
    width: 35px;
    height: 35px;
  }
  .img-company {
    line-height: 33px;
  }
  .home-profile h3 {
    font-size: 14px;
  }
  .home-profile table label {
    font-size: 10px;
  }
  .content-company {
    width: calc(100% - 50px);
    padding-left: 7px;
  }
  .platform-overview {
    padding: 25px 0;
  }
  .platform-overview h2 {
    font-size: 25px;
  }
  .platform-overview h4, .normal-info h3 {
    font-size: 13px;
  }
  .platform-normal h3 {
    font-size: 16px;
  }
  .platform-normal p {
    font-size: 11px;
  }
  .normal-header b {
    font-size: 13px;
  }
  .normal-info .check-list {
    margin: 15px 0;
  }
  .normal-info .btn-transparent, .normal-info .btn-blue {
    min-width: 80px;
    font-size: 10px;
    height: 33px;
    line-height: 31px;
  }
  .platform-item label {
    font-size: 10px;
  }
  .normal-info li {
    font-size: 11px;
  }
  section h2 {
    font-size: 25px;
  }
  .partners {
    padding: 40px 0;
  }
  section h4 {
    font-size: 15px;
  }
  .head-form.custom-head-form button[type=submit] {
    padding: 0 30px;
    font-size: 10px;
  }
  .head-form.custom-head-form button[type=submit] svg {
    width: 10px;
  }
  .footer-logo, .footer-call {
    width: 100%;
  }
  .partners li {
    margin-bottom: 18px;
    padding: 0 5px;
  }
  .footer-nav, .footer-contact {
    width: 50%;
    float: left;
    margin-bottom: 20px;
  }
  .footer-logo {
    margin-bottom: 20px;
    img {
      max-width: 150px;
    }
  }
  footer .footer-flex {
    display: block;
  }
  .footer-call {
    clear: both;
  }
  .tag {
    font-size: 9px;
    padding: 5px 8px 4px 10px;
  }
  header .home-link {
    width: 130px;
    height: 35px;
  }
  .navbar-toggler {
    margin: 5px;
  }
  .platform-demo p {
    font-size: 12px;
  }
  .form-control {
    height: 35px;
  }
  .platform-demo {
    padding: 25px 2%;
  }
  .form-group label {
    font-size: 9px;
  }
  .platform-demo h3 {
    font-size: 18px;
    margin: 0 0 7px;
  }
  .home-head {
    padding: 85px 0 15px;
  }
  .btn-transparent, .btn-blue {
    font-size: 10px;
    height: 33px;
    line-height: 31px;
  }
  .copyright {
    padding: 20px 0;
    font-size: 11px;
  }
  .footer-call .btn-transparent, .footer-call .btn-blue {
    width: auto;
    margin-right: 10px;
  }
  .content-employee {
    width: calc(100% - 40px);
    padding-left: 5px;
  }
  .api .api-element-right {
    display: none;
  }
  .api .api-element-left {
    width: 100%;
  }
  .api .api-element-left .btn-double .link-icon {
    height: 32px;
    width: 32px;
  }
  .api .api-element-left .btn-double .link-content {
    line-height: 32px;
    font-size: 10px;
    width: 190px;
    max-width: calc(100% - 40px);
  }
  .btn-double .link-content svg {
    right: 12px;
    width: 9px;
  }
  footer .socials a svg {
    width: 22px;
  }
  .customers h4 {
    font-size: 18px;
  }
  .customers h3 {
    font-size: 28px;
  }
  .feeback-list {
    display: block;
  }
  .feeback-list li {
    width: 100%;
    margin-bottom: 20px;
  }
  .feedback {
    padding: 30px 0 10px;
  }
  .our-data .data-left h3 {
    font-size: 16px;
    margin: 0 0 20px;
  }
  .collect-list p {
    font-size: 11px;
    margin-top: 7px;
    padding: 0 7px;
  }
  .collect-list .box-icon {
    width: 70px;
    height: 70px;
  }
  .validate-data h5 {
    font-size: 11px;
  }
  .validate-data p {
    font-size: 12px;
  }
  .validate-data table td {
    padding: 12px;
  }
  .validate-data .validate-icon {
    width: 50px;
  }
  .validate-data p {
    width: calc(100% - 60px);
  }
  .contact-form p {
    font-size: 12px;
    margin-bottom: 5px;
  }
  .contact-form h5 {
    font-size: 11px;
    margin-bottom: 7px;
  }
  .socials-o a {
    width: 35px;
  }
  .contact-us h1 {
    font-size: 32px;
  }
  .accordion-header {
    font-size: 15px;
  }
  .custom-content ol li {
    font-size: 12px;
    padding: 0 0 0 7px;
  }
  .modal-below h3 {
    font-size: 15px;
  }
}

@media (max-width: 479px) {
  .api .api-element-left {
    .sep {
      margin: 15px auto;
    }

    h3 {
      text-align: center;
    }

    h5 {
      line-height: 1.5em;
    }
    
    .btn-double .link-content {
      width: 100%;
    }
  }
  
  .partners.small-partners h3 {
    font-size: 16px;
  }
  footer .socials a svg {
    fill: #8b98ab;
    width: 18px;
  }
  .checko-list li {
    font-size: 12px;
  }
  .home-head .home-head-left h1 {
    font-size: 16px;
  }
  .home-head .home-head-left p, .normal-header p {
    font-size: 12px;
  }
  .home-head .home-head-left .head-form {
    display: block;

  }
  .head-form input[type=email] {
    font-size: 12px;
    width: 100%;
    margin-bottom: 10px;
  }
  .head-form button[type=submit] {
    font-size: 11px;
    height: 33px;
    width: 100%;
  }
  .employee-info .img-employee, .img-company {
    display: none;
  }
  .home-profile table td {
    display: block;
    margin-bottom: 5px;
  }
  .container, .wrap {
    padding-left: 15px;
    padding-right: 15px;
  }
  .platform-overview h2 {
    font-size: 20px;
  }
  .platform-overview h4, .normal-info h3 {
    font-size: 12px;
  }
  .input-box .form-control {
    padding: 0 30px;
    height: 36px;
  }
  .input-box .left-icon, blockquote p, .platform-normal p, .normal-info li {
    font-size: 10px;
  }
  .input-box .right-icon {
    font-size: 12px;
  }
  .platform-normal h3 {
    font-size: 14px;
  }
  .platform-normal img {
    max-height: 45px;
  }
  .normal-header span, .normal-header p {
    float: none;
  }
  .normal-header {
    text-align: center;
    b {
      font-size: 12px;
    }

  }
  .normal-info .check-list {
    column-count: 1;
  }
  .normal-info .btn-transparent, .normal-info .btn-blue {
    max-width: 100%;
    min-width: 135px;
    display: block;
    margin: 0 auto 10px;
  }
  .custom-dropdown ul li {
    font-size: 10px;
    padding: 3px 15px;
  }
  section h2 {
    font-size: 20px;
  }
  .partners li {
    width: 32%;
    margin-bottom: 15px;
    padding: 0 10px;
  }
  .head-form {
    display: block;
  }
  .footer-logo img {
    max-width: 120px;
  }
  .footer-nav a, footer p {
    font-size: 11px;
  }
  footer h4 {
    font-size: 11px;
    margin: 0 0 12px;
  }
  .footer-call .btn-transparent, .footer-call .btn-blue {
    margin-right: 5px;
  }
  footer .footer-flex {
    padding-bottom: 15px;
  }
  footer {
    padding-top: 30px;
  }
  .platform-demo p, .platform-demo h3 b {
    font-size: 10px;
  }
  .form-group label {
    font-size: 8px;
  }
  .normal-header b {
    float: none;
  }
  .platform-demo .btn-blue {
    display: block;
    margin: 0 auto;
  }
  .form-control {
    height: 30px;
    font-size: 10px;
    padding: 0 10px;
  }
  .form-inline .form-group {
    width: 100%;
  }
  .platform-demo h3 b {
    margin: 3px 0;
  }
  .platform-demo h3 {
    font-size: 14px;
  }
  .copyright {
    padding: 15px 0;
    font-size: 9px;
  }
  section h4 {
    font-size: 13px;
  }
  .custom-dropdown ul li span {
    left: 5px;
  }
  .feedback {
    padding: 30px 0 10px;
  }
  .customers h4 {
    font-size: 15px;
  }
  .customers h3 {
    font-size: 20px;
    // margin-bottom: 50px;
  }
  .feeback-list li .feed-content {
    font-size: 12px;
    margin-bottom: 15px;
    padding: 12px;
  }
  textarea.form-control {
    height: 70px;
    padding: 10px 10px;
  }
  .data-circle {
    width: 250px;
    height: 250px;
  }
  .data-circle li {
    width: 45px;
    height: 45px;
    svg {
      width: 18px;
    }
  }
  .data-circle li span {
    padding: 3px 5px;
    font-size: 7px;
  }
  .our-data .data-left h1 {
    font-size: 30px;
  }
  .our-data .data-left h3 {
    font-size: 14px;
    margin: 0 0 15px;
  }
  .collect-list {
    display: block;
    background: none;
    li {
      margin-bottom: 15px;
    }
  }
  .collect-data {
    padding: 25px 0 15px;
  }
  .validate-data table td, .validate-data table tr:last-child td {
    display: block;
    border: 0;
    border-bottom: 1px solid #dfe4ec;
  }
  .contact-form {
    padding: 20px;
  }
  .contact-form .sep {
    margin: 10px 0 15px;
  }
  .accordion-header {
    font-size: 14px;
  }
  .custom-content ol li {
    font-size: 11px;
    padding: 0 0 0 5px;
  }
  .book-demo {
    max-width: 82%;
    padding: 20px 25px;
  }
  .book-demo h2 {
    font-size: 18px;
  }
  .signup-block p, .book-demo p {
    font-size: 11px;
  }
  .form-half .form-item, .form-half .form-item:last-child {
    width: 100%;
    margin-left: 0;
  }
  .form-item {
    font-size: 10px;

  }
  .modal-below h3 {
    font-size: 13px;
  }
  .modal-below .below-img img {
    width: 17%;
    margin: 7px 7px;
  }
}

.not-found {
  padding: 20vh 0;
  text-align: center;

  h1 {
    font: 40px 'nunito-semibold';
    color: #2997fe;
    margin-bottom: 10px;
  }

  p {
    margin: 0;
    font: 14px 'nunito-semibold';
  }

  .hot-line {
    @include width(300px);
    height: 2px;
    background-color: #2997fe;
    margin: 10px auto;
  }
}